// assets
import { UserOutlined } from '@ant-design/icons';

const company = {
  id: 'system-group',
  title: 'System',
  type: 'group',
  children: [
    {
      id: 'users',
      title: 'Users',
      type: 'item',
      url: '/system/users',
      icon: UserOutlined,
    },
  ],
};

export default company;

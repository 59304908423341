import { types } from 'mobx-state-tree';
import Actions from './Actions';
import Views from './Views';
import User from '../User';
import Person from '../Person';
import Position from '../Position';
import Report from '../Report';

const CandidateCV = types.model({
  id: types.string,
  fileName: types.optional(types.string, ''),
  createdDate: types.optional(types.string, new Date().toLocaleString()),
  updatedDate: types.optional(types.string, new Date().toLocaleString()),
});

// const StoreProps = {
//   id: types.string,
//   userId: types.maybeNull(types.string),
//   organizationId: types.maybeNull(types.string),
//   positionId: types.maybeNull(types.string),
//   isDeleted: types.optional(types.boolean, false),
//   createdDate: types.optional(types.string, new Date().toLocaleString()),
//   updatedDate: types.optional(types.string, new Date().toLocaleString()),
//   person: types.maybeNull(Person),
//   user: types.maybeNull(User),
//   reports: types.optional(types.array(Report), []),
//   candidateCv: types.maybeNull(CandidateCV),
// };

const StoreProps = {
  id: types.string,
  userId: types.maybeNull(types.string),
  organizationId: types.maybeNull(types.string),
  position: types.maybeNull(Position),
  isDeleted: types.optional(types.boolean, false),
  createdDate: types.optional(types.string, new Date().toLocaleString()),
  updatedDate: types.optional(types.string, new Date().toLocaleString()),
  person: types.maybeNull(Person),
  user: types.maybeNull(User),
  reports: types.optional(types.array(Report), []),
  candidateCv: types.maybeNull(CandidateCV),
};

const Store = types.model(StoreProps).actions(Actions).views(Views);

export default Store;

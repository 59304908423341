const FIT_SCORE_LABELS = {
  Great: 'Great',
  Very_Good: 'Very Good',
  Good: 'Good',
  Moderate: 'Moderate',
  Poor: 'Poor',
  Very_Poor: 'Very Poor',

};

export const fitScoreColors = {
  success: '#52c41a',
  warning: '#faad14',
  primary: '#1976d2',
  customGrey: '#00000042',
  error: '#ff4d4f',
  black: '#262626',
};

export const fitScoreColorsNames = {
  success: 'success',
  warning: 'warning',
  primary: 'primary',
  error: 'error',
  customGrey: 'customGrey',
};
export const fitScoreToColor = (score, hexColor = false) => {
  if (score === null || score === undefined) {
    return hexColor ? fitScoreColors.customGrey : 'customGrey';
  }

  if (score > 70) {
    return hexColor ? fitScoreColors.success : 'success';
  }

  return hexColor ? fitScoreColors.warning : 'warning';
  // if (score > 60) {
  //   return hexColor ? fitScoreColors.warning : 'warning';
  // }
  //
  // return hexColor ? fitScoreColors.error : 'error';
};

export const fitScoreMapping = (score) => {
  const color = fitScoreToColor(score);
  let label;

  switch (true) {
    case score > 90:
      label = FIT_SCORE_LABELS.Great;
      break;
    case score > 80 && score <= 90:
      label = FIT_SCORE_LABELS.Very_Good;
      break;
    case score > 70 && score <= 80:
      label = FIT_SCORE_LABELS.Good;
      break;
    case score > 60 && score <= 70:
      label = FIT_SCORE_LABELS.Moderate;
      break;
    case score > 50 && score <= 60:
      label = FIT_SCORE_LABELS.Poor;
      break;
    case score <= 50:
    default:
      label = FIT_SCORE_LABELS.Very_Poor;
      break;
  }

  return {
    color,
    label,
  };
};

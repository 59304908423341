import { getRoot } from 'mobx-state-tree';
import { CANDIDATE_STATUS } from '../../../constants/candidate';

const Views = (self) => ({
  get fullName() {
    return `${self.person.firstName} ${self.person.lastName}`;
  },

  get addedDate() {
    return new Date(self.createdDate).toDateString();
  },

  get reportsList() {
    return self.reports.slice().sort((a, b) => new Date(b.date) - new Date(a.date));
  },

  get lastReport() {
    return self.reportsList[self.reports.length - 1];
  },

  get status() {
    if (self.lastReport) {
      return self.lastReport.status;
    }
    return CANDIDATE_STATUS.New;
  },

  // get position() {
  //   const { positions } = getRoot(self);
  //   return positions.positionsList.get(self.positionId);
  // },
});

export default Views;

import { flow } from 'mobx-state-tree';
import { refreshToken } from '../../../api/users.api';
import { getOrganizationFitMetrics, updateOrganizationFitMetrics } from '../../../api/organizations.api';

const Actions = (self) => ({
  init: (data) => {
    self.id = data.id;
    self.organizationId = data.organizationId;
    self.relevantWorkExperience = data.relevantWorkExperience;
    self.educationAndCourses = data.educationAndCourses;
    self.collaborationAndCommunication = data.collaborationAndCommunication;
    self.employerEngagementLevel = data.employerEngagementLevel;
    self.drivenAtWorkBy = data.drivenAtWorkBy;
    self.passionRate = data.passionRate;
    self.socialSkills = data.socialSkills;
    self.isLoaded = true;
  },

  getOrgFitMetrics: flow(function* getOrgFitMetrics(newCandidate) {
    try {
      const result = yield getOrganizationFitMetrics();
      if (result?.isSuccess) {
        self.init(result.result);
      }
      return result;
    } catch (e) {
      console.error(e);
      if (e.statusCode === 401) {
        yield refreshToken();
        yield self.getOrgFitMetrics();
      }
      return null;
    }
  }),

  updateOrgFitMetrics: flow(function* updateOrgFitMetrics(data) {
    try {
      data.id = self.id;
      const result = yield updateOrganizationFitMetrics(data);
      if (result?.isSuccess) {
        self.init(result.result);
      }
      return result;
    } catch (e) {
      console.error(e);
      if (e.statusCode === 401) {
        yield refreshToken();
        yield self.getOrgFitMetrics();
      }
      return null;
    }
  }),
});

export default Actions;

// assets
import { UserAddOutlined, TeamOutlined } from '@ant-design/icons';

const candidates = {
  id: 'group-candidates',
  title: 'Candidates',
  type: 'group',
  children: [
    {
      id: 'candidates',
      title: 'Candidates List',
      type: 'item',
      url: '/candidates',
      icon: TeamOutlined,
      // breadcrumbs: false,
    },
    {
      id: 'new-candidate',
      title: 'New Candidate ',
      type: 'item',
      url: '/candidates/new-candidate',
      icon: UserAddOutlined,
      // breadcrumbs: false,
    },
  ],
};

export default candidates;

import ajaxUtils from './ajaxUtils';

export const fetchUser = () => ajaxUtils.get('/users/me');

export const login = ({ email, password }) => ajaxUtils.post('/users/login', { email, password });

export const logout = () => ajaxUtils.get('/users/logout');

export const refreshToken = () => ajaxUtils.get('/users/refresh-tokens');

export const signup = (payload) => ajaxUtils.post('/users/registration', payload);

export const getOrganizationUsers = () => ajaxUtils.get('/users/team');

export const updateUser = (payload) => ajaxUtils.patch('/users/update', payload);

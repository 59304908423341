import { flow, getRoot } from 'mobx-state-tree';
import { refreshToken } from '../../../api/users.api';
import { CANDIDATE_STATUS } from '../../../constants/candidate';

const Actions = (self) => ({
  // createCandidateReport: flow(function* createCandidateReport(candidateId) {
  //   try {
  //     const { user, candidates } = getRoot(self);
  //
  //     const payload = {
  //       candidateId,
  //       stauts: CANDIDATE_STATUS.New,
  //       statusInternal: CANDIDATE_STATUS.New,
  //     };
  //     const result = yield createNewCandidateReport(payload);
  //     if (result?.isSuccess) {
  //       const candidate = candidates.candidatesList.get(candidateId);
  //       candidate.updateStatus(CANDIDATE_STATUS.New);
  //
  //       result.result.user = user.snapshot;
  //       self.candidatesList.set(result.result.id, result.result);
  //     }
  //     return result;
  //   } catch (e) {
  //     console.error(e);
  //     if (e.statusCode === 401) {
  //       yield refreshToken();
  //       yield self.createCandidate(newCandidate);
  //     } else if (e.statusCode === 409) {
  //       return { isSuccess: false, error: 'The Candidate already exists in the company' };
  //     }
  //     return { isSuccess: false, error: e.message };
  //   }
  // }),
});

export default Actions;

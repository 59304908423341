import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project import
import config from 'config';
import Logo from './Logo';

// ==============================|| MAIN LOGO ||============================== //

function LogoSection({ sx, to }) {
  return (
    <ButtonBase disableRipple component={Link} to={!to ? config.defaultPath : to} sx={sx}>
      <Logo />
    </ButtonBase>
  );
}

LogoSection.propTypes = {
  sx: PropTypes.object,
  to: PropTypes.string,
};

export default LogoSection;

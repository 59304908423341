import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import 'react-notifications/lib/notifications.css';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Toolbar, useMediaQuery } from '@mui/material';

// project import
import navigation from 'menu-items';
import Breadcrumbs from 'components/@extended/Breadcrumbs';

// types
import { useMST } from '../../hooks/useMST';
import Header from './Header';
import Drawer from './Drawer';

function MainLayout() {
  const { appState, positions } = useMST((store) => ({ appState: store.appState, positions: store.positions }));
  const { drawerOpen } = appState;

  // drawer toggler
  const [open, setOpen] = useState(drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    appState.openDrawer(!open);
    localStorage.setItem('drawer-open', !open);
  };

  // const theme = useTheme();
  // const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));
  // set media wise responsive drawer
  // useEffect(() => {
  //   setOpen(!matchDownLG);
  //   appState.openDrawer(!matchDownLG);
  // }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
  }, [drawerOpen]);

  useEffect(() => {
    positions.fetchPositionsList();
  }, []);

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Box component="main" sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
        <Toolbar />
        <Breadcrumbs navigation={navigation} title card={false} divider={false} />
        <Outlet />
      </Box>
    </Box>
  );
}

export default observer(MainLayout);

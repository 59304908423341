import { flow, getRoot } from 'mobx-state-tree';
import {
  createNewCandidate,
  fetchCandidatesPagination,
  updateCandidateAPI,
  uploadCandidateResume,
} from '../../../api/candidates.api';
import { refreshToken } from '../../../api/users.api';
import { getPositionById } from '../../../api/positions.api';
import { CANDIDATE_STATUS } from '../../../constants/candidate';

const Actions = (self) => ({

  setPositionId(value) {
    self.filters.positionId = value;
  },
  setSearchPhrase(value) {
    self.filters.searchPhrase = value;
  },

  setPagination(page, rowsPerPage) {
    self.page = page;
    if (rowsPerPage) {
      self.rowsPerPage = rowsPerPage;
    }
  },

  fetchCandidatesList: flow(function* fetchCandidatesList() {
    try {
      self.setIsLoading(true);
      const filters = {};
      if (self.filters.positionId) {
        filters.positionId = self.filters.positionId;
      }

      if (self.filters.searchPhrase) {
        filters.candidateName = self.filters.searchPhrase;
      }
      const filtersQuery = new URLSearchParams(filters).toString();

      const offset = (self.page * self.rowsPerPage);

      const response = yield fetchCandidatesPagination(offset, self.rowsPerPage, filtersQuery);
      if (response?.isSuccess) {
        self.candidatesList = {};
        self.total = response.result.count;
        response.result.results.forEach((candidate) => {
          self.candidatesList.set(candidate.id, candidate);
        });
        self.setIsLoading(false);
        return response;
      }
    } catch (e) {
      console.error(e);
      if (e.statusCode === 401) {
        yield refreshToken();
        yield self.fetchCandidatesList();
      }
    }
    return null;
  }),

  uploadCV: flow(function* uploadCV(candidateId, file, candidateCv) {
    try {
      const candidateCV = new FormData();
      candidateCV.append('file', file);
      let query = '';
      if (candidateCv?.id) {
        query = `id=${candidateCv?.id}`;
      }

      const result = yield uploadCandidateResume(candidateId, candidateCV, query).then((res) => res.json());
      if (!result.isSuccess) {
        console.error('Failed upload file', { result });
      } else {
        const { result: { id } } = result;
        const candidateToUpdate = self.candidatesList.get(candidateId);
        candidateToUpdate.addCv(id);
        console.info('FIle uploaded successfully', { result });
      }
      return result;
    } catch (e) {
      console.error(e);
      if (e.statusCode === 401) {
        yield refreshToken();
        return yield self.uploadCV(candidateId, file);
      }

      console.error('Failed to upload file', e);
      return { isSuccess: false, error: e.message };
    }
  }),

  createCandidate: flow(function* createCandidate(newCandidate, submitType) {
    try {
      const { user } = getRoot(self);
      const { file } = newCandidate;
      delete newCandidate.file;
      const result = yield createNewCandidate(newCandidate);

      if (result?.isSuccess) {
        if (file) {
          yield self.uploadCV(result.result.id, file);
        }
        result.result.user = user.snapshot;
        self.candidatesList.set(result.result.id, result.result);

        if (submitType === 'SUBMIT_AND_ACTION') {
          self.candidatesList.get(result.result.id).updateCandidateReport(CANDIDATE_STATUS.Analyzing);
        }
      }
      return result;
    } catch (e) {
      console.error(e);
      if (e.statusCode === 401) {
        yield refreshToken();
        yield self.createCandidate(newCandidate);
      } else if (e.statusCode === 409) {
        return { isSuccess: false, error: 'The Candidate already exists in the company' };
      }
      return { isSuccess: false, error: 'Failed to add new candidate. please try again' };
    }
  }),

  updateCandidate: flow(function* updateCandidate(candidateId, dataToUpdate, submitType) {
    try {
      const { file } = dataToUpdate;
      delete dataToUpdate.file;

      const candidateToUpdate = self.candidatesList.get(candidateId);

      const personUpdateResult = yield candidateToUpdate.person.updatePerson(dataToUpdate);

      const payload = {
        id: candidateId,
        positionId: dataToUpdate.positionId,
      };

      const candidateUpdateResult = yield updateCandidateAPI(payload);

      if (candidateUpdateResult.isSuccess) {
        const response = yield getPositionById(candidateUpdateResult.result.positionId);
        if (response?.isSuccess) {
          candidateToUpdate.updatePosition(response.result);
        } else {
          console.error('Failed updating candidate position', response);
        }
      }

      if (file) {
        yield self.uploadCV(candidateToUpdate.id, file, candidateToUpdate.candidateCv);
      }

      if (submitType === 'SUBMIT_AND_ACTION') {
        candidateToUpdate.updateCandidateReport(CANDIDATE_STATUS.Analyzing);
      }

      return {
        isSuccess: personUpdateResult.isSuccess && candidateUpdateResult.isSuccess,
        result: {
          ...personUpdateResult.result,
          ...candidateUpdateResult.result,
        },
      };
    } catch (e) {
      console.error(e);
      if (e.statusCode === 401) {
        yield refreshToken();
        yield self.updateCandidate(candidateId, dataToUpdate);
      } else if (e.statusCode === 409) {
        return { isSuccess: false, error: 'The Candidate already exists in the company' };
      }
      return { isSuccess: false, error: 'Failed to update candidate. please try again' };
    }
  }),

  deleteCandidate: flow(function* deleteCandidate(candidateId) {
    try {
      const payload = {
        id: candidateId,
        deletedAt: new Date().toDateString(),
      };

      const result = yield updateCandidateAPI(payload);
      if (result.isSuccess) {
        self.candidatesList.delete(candidateId);
      }
      return result;
    } catch (e) {
      console.error(e);
      if (e.statusCode === 401) {
        yield refreshToken();
        yield self.deleteCandidate(candidateId);
      } else if (e.statusCode === 409) {
        return { isSuccess: false, error: 'The Candidate already exists in the company' };
      }
      return { isSuccess: false, error: e.message };
    }
  }),

  setIsLoading(value) {
    self.isLoading = value;
  },
});

export default Actions;

// project import
import reports from './reports';
import company from './company';
import navigation from './navigation';
import candidates from './candidates';
import system from './system';
// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [navigation, candidates, reports, company, system],
};

export default menuItems;

import { getSnapshot } from 'mobx-state-tree';

const Views = (self) => ({
  get fullName() {
    return `${self.firstName} ${self.lastName}`;
  },

  get snapshot() {
    return getSnapshot(self);
  },

  get addedDate() {
    return new Date(self.createdDate).toDateString();
  },
});

export default Views;

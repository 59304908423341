const serverUrl = process.env.REACT_APP_SERVER_URL;

class AjaxUtils {
  async patch(route, data, isFullUrl = false) {
    const options = {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };
    const url = isFullUrl ? new URL(route) : new URL(serverUrl + route);
    const request = new Request(url.toString(), options);

    return this._fetch(request);
  }

  async post(route, data, isFullUrl = false) {
    const options = {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };
    const url = isFullUrl ? new URL(route) : new URL(serverUrl + route);
    const request = new Request(url.toString(), options);

    return this._fetch(request);
  }

  async get(route, isFullUrl = false, responseType = 'json') {
    const options = {
      method: 'GET',
      credentials: 'include',
      responseType,
    };
    const url = isFullUrl ? new URL(route) : new URL(serverUrl + route);
    const request = new Request(url.toString(), options);

    return this._fetch(request);
  }

  async delete(route, isFullUrl = false) {
    const options = {
      method: 'DELETE',
      credentials: 'include',
    };
    const url = isFullUrl ? new URL(route) : new URL(serverUrl + route);
    const request = new Request(url.toString(), options);

    return this._fetch(request);
  }

  async getFile(route, isFullUrl = false, responseType = 'json') {
    const options = {
      method: 'GET',
      credentials: 'include',
      responseType,
    };
    const url = isFullUrl ? new URL(route) : new URL(serverUrl + route);
    const request = new Request(url.toString(), options);

    return this._fetch(request, false);
  }

  // eslint-disable-next-line class-methods-use-this
  async _fetch(request, toJson = true) {
    const fetchResult = await fetch(request);
    let result;
    try {
      if (toJson) {
        result = await fetchResult.json();
      }
    } catch (e) {
      if (fetchResult && !fetchResult.ok) {
        throw fetchResult;
      }

      throw e;
    }

    if (fetchResult.ok) {
      return result;
    }
    throw result;
  }
}

export default new AjaxUtils();

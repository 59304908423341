import { types } from 'mobx-state-tree';
import Actions from './Actions';
import Views from './Views';

const StoreProps = {
  id: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  profileUrl: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  phone: types.maybeNull(types.string),
  birthdate: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  gender: types.maybeNull(types.number),
  materialStatus: types.maybeNull(types.number),
  createdDate: types.optional(types.string, new Date().toLocaleString()),
  updatedDate: types.optional(types.string, new Date().toLocaleString()),
};

const Store = types.model(StoreProps).actions(Actions).views(Views);

export default Store;

import { types } from 'mobx-state-tree';
import Actions from './Actions';
import Views from './Views';
import Position from '../Position';

const StoreProps = {
  positionsList: types.map(Position),
  isLoaded: types.optional(types.boolean, false),
};

const Store = types.model(StoreProps).actions(Actions).views(Views);

export default Store;

import { POSITION_STATUS } from '../../../constants/position';

const Views = (self) => ({
  get positionsArray() {
    return Array.from(self.positionsList.values());
  },

  get openPositionsArray() {
    return Array.from(self.positionsList.values()).filter((pos) => pos.status === POSITION_STATUS.Open);
  },

});

export default Views;

import ajaxUtils from './ajaxUtils';

export const getOrganization = (orgId) => ajaxUtils.get(`/organizations/${orgId}`);

export const updateOrganization = (payload) => ajaxUtils.patch('/organizations/update', payload);

export const getOrganizationFitMetrics = () => ajaxUtils.get('/organizations/fit-metrics');

export const updateOrganizationFitMetrics = (payload) => ajaxUtils.post('/organizations/fit-metrics/upsert', payload);

export const getOrganizationKeywords = () => ajaxUtils.get('/organizations/keywords');
export const upsertOrganizationKeywords = (payload) => ajaxUtils.post('/organizations/keywords/upsert', payload);

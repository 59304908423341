import { types } from 'mobx-state-tree';
import Actions from './Actions';
import Views from './Views';
import User from '../User';
import Office from '../Office';
import { POSITION_STATUS } from '../../constants/position';
import FitMetrics from '../FitMetrics';

const RemoteWorkPolicy = types.model({
  remoteWorkPolicyId: types.number,
  value: types.optional(types.string, ''),
});

const EmploymentMode = types.model({
  employmentModeId: types.number,
  value: types.optional(types.string, ''),
});

const EmploymentType = types.model({
  employmentTypeId: types.number,
  value: types.optional(types.string, ''),
});

const AddedByUser = types.model({
  firstName: types.optional(types.string, ''),
  lastName: types.optional(types.string, ''),
}).views((self) => ({
  get name() {
    return `${self.firstName}  ${self.lastName}`;
  },
}));

const StoreProps = {
  id: types.maybeNull(types.string),
  // user: types.maybeNull(AddedByUser),
  userId: types.maybeNull(types.string),
  organizationId: types.maybeNull(types.string),
  companyOfficeId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  status: types.optional(types.string, POSITION_STATUS.Open),
  remoteWorkPolicy: types.maybeNull(RemoteWorkPolicy),
  employmentMode: types.maybeNull(EmploymentMode),
  employmentType: types.maybeNull(EmploymentType),
  employmentTypeId: types.maybeNull(types.number),
  employmentModeId: types.maybeNull(types.number),
  remoteWorkPolicyId: types.maybeNull(types.number),
  deletedAt: types.maybeNull(types.string),
  createdDate: types.optional(types.string, new Date().toLocaleString()),
  updatedDate: types.optional(types.string, new Date().toLocaleString()),
  candidateCount: types.maybeNull(types.number),
  reportsCount: types.maybeNull(types.number),
  user: types.maybeNull(User),
  companyOffice: types.maybeNull(Office),
  positionFitMetrics: types.maybeNull(FitMetrics),
};

const Store = types.model(StoreProps).actions(Actions).views(Views);

export default Store;

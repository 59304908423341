import { types } from 'mobx-state-tree';
import Actions from './Actions';
import Views from './Views';

const UserProps = {
  userId: types.maybe(types.string),
  organizationId: types.maybe(types.string),
  email: types.maybe(types.string),
  firstName: types.maybe(types.string),
  lastName: types.maybe(types.string),
  isLoaded: types.optional(types.boolean, false),
  createdDate: types.optional(types.string, new Date().toLocaleString()),
  status: types.optional(types.string, 'Active'),
};

const User = types.model(UserProps).actions(Actions).views(Views);

export default User;

import ajaxUtils from './ajaxUtils';

export const getLast5Reports = (byUser) => ajaxUtils.get(`/dashboard/candidates-last-reports?byUser=${byUser}`);

export const getAllReports = (byUser) => ajaxUtils.get(`/candidates/report/list?byUser=${byUser}`);

export const getAllReportsPagination = (offset, limit, filtersQuery) => ajaxUtils.get(`/fit-report/all?offset=${offset}&limit=${limit}&${filtersQuery}`);

export const createReport = (payload) => ajaxUtils.post('/candidates/report/create', payload);

export const updateReport = (payload) => ajaxUtils.patch('/candidates/report/update', payload);

export const getReportById = (reportId) => ajaxUtils.get(`/fit-report/get/${reportId}`);

import ajaxUtils from './ajaxUtils';

const serverUrl = process.env.REACT_APP_SERVER_URL;

export const fetchAllCandidates = () => ajaxUtils.get('/candidates/list');

export const fetchCandidatesPagination = (offset, limit, filtersQuery) => ajaxUtils.get(`/candidates/all?offset=${offset}&limit=${limit}&${filtersQuery}`);

export const createNewCandidate = (candidate) => ajaxUtils.post('/candidates/create', candidate);

export const updateCandidateAPI = (candidate) => ajaxUtils.patch('/candidates/update', candidate);

export const getCandidateResume = (candidateId) => fetch(`${serverUrl}/candidate-cv/get/${candidateId}`, { method: 'get', credentials: 'include' });

export const uploadCandidateResume = (candidateId, data, query) => fetch(`${serverUrl}/candidate-cv/upload/${candidateId}?${query}`, { body: data, method: 'post', credentials: 'include' });

export const DeleteCandidateResume = (candidateId) => ajaxUtils.delete(`/candidate-cv/delete/${candidateId}`);

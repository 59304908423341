import React from 'react';
import { Grid, Typography } from '@mui/material';

const ComingSoon = () => (
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
    style={{ minHeight: '60vh' }}
  >

    <Grid item xs={3}>
      <Typography variant="h2">Coming Soon...</Typography>
    </Grid>

  </Grid>
);

export default ComingSoon;

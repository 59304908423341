const Actions = (self) => ({
  activeItem(value) {
    self.openItem = value;
  },

  activeComponent(value) {
    self.openComponent = value;
  },

  openDrawer(value) {
    self.drawerOpen = value;
  },

  openComponentDrawer(value) {
    self.componentDrawerOpen = value;
  },
});

export default Actions;

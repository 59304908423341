import { types } from 'mobx-state-tree';
import { get } from 'lodash';
import Actions from './Actions';
import Views from './Views';
import { fitScoreMapping } from '../../utils/fit-score';

const listFilters = types.model({
  positionId: types.maybeNull(types.string),
  searchPhrase: types.maybeNull(types.string),
});

const ReportListItem = types.model({
  id: types.maybeNull(types.string),
  createdDate: types.maybeNull(types.string),
  candidateName: types.maybeNull(types.string),
  positionId: types.maybeNull(types.string),
  position: types.maybeNull(types.string),
  user: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  fitScore: types.maybeNull(types.number),
  fitResult: types.maybeNull(types.string),
});

const StoreProps = {
  list: types.map(ReportListItem),
  total: types.optional(types.number, 0),
  filters: types.optional(listFilters, {}),
  page: types.optional(types.number, 0),
  rowsPerPage: types.optional(types.number, 25),
  isLoading: types.optional(types.boolean, true),
};

const Store = types.model(StoreProps).actions(Actions).views(Views);

export default Store;

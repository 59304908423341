import { flow } from 'mobx-state-tree';
import { getOrganization, getOrganizationKeywords, upsertOrganizationKeywords } from '../../../api/organizations.api';
import { getOrganizationUsers } from '../../../api/users.api';
import User from '../../User';

const Actions = (self) => ({

  init: (organization) => {
    self.id = organization.id;
    self.name = organization.name;
    self.company = organization.company;
    self.isLoaded = true;
  },

  fetchOrganizationDetails: flow(function* fetchOrganizationDetails(orgId) {
    try {
      const response = yield getOrganization(orgId);
      if (response?.isSuccess) {
        self.init(response.result);
      }

      return response.result;
    } catch (e) {
      console.error(e);
      return null;
    }
  }),

  updateOrganizationDetails: flow(function* updateOrganizationDetails(data) {
    try {
      const companyDetails = yield self.company.updateCompanyDetails(data);
      return companyDetails;
    } catch (e) {
      console.error(e);
      return null;
    }
  }),

  getOrganizationUsersList: flow(function* getOrganizationUsersList() {
    try {
      const response = yield getOrganizationUsers();
      if (response?.isSuccess) {
        self.users = [];
        response.result.forEach((item) => {
          const newUser = User.create();
          newUser.init(item);
          self.users.push(newUser);
        });
        self.usersLoaded = true;
      }

      return response.result;
    } catch (e) {
      console.error(e);
      return null;
    }
  }),

  getKeywords: flow(function* getKeywords() {
    try {
      const response = yield getOrganizationKeywords();
      if (response?.isSuccess) {
        self.keywords = response.result.keywords;
        self.keywordsId = response.result.id;
      }

      return response.result;
    } catch (e) {
      console.error(e);
      return null;
    }
  }),

  upsertKeywords: flow(function* upsertKeywords(keywords) {
    try {
      const payload = {
        keywords,
      };

      if (self.keywordsId) {
        payload.id = self.keywordsId;
      }

      const response = yield upsertOrganizationKeywords(payload);
      if (response?.isSuccess) {
        self.keywords = response.result.keywords;
        self.keywordsId = response.result.id;
      }

      return response;
    } catch (e) {
      console.error(e);
      return null;
    }
  }),

});

export default Actions;

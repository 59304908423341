import React from 'react';
import { CircularProgress, Grid, Typography } from '@mui/material';

// eslint-disable-next-line react/prop-types
const PageSkeleton = ({ text }) => (
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
    style={{ minHeight: '100vh' }}
  >

    <Grid item xs={3}>
      {!text ? <CircularProgress /> : (
        <Typography>{text}</Typography>
      )}
    </Grid>

  </Grid>
);

export default PageSkeleton;

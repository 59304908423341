import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
// material-ui
import {
  Box, List, Typography, ListSubheader,
} from '@mui/material';

// project import
import NavItem from './NavItem';
import { useMST } from '../../../../../hooks/useMST';

// ==============================|| NAVIGATION - LIST GROUP ||============================== //

function NavGroup({ item }) {
  const { appState } = useMST((store) => ({ appState: store.appState }));
  const { drawerOpen } = appState;

  const navCollapse = item.children?.filter((navItem) => !navItem.hidden).map((menuItem) => {
    switch (menuItem.type) {
      case 'collapse':
        return (
          <Typography key={menuItem.id} variant="caption" color="error" sx={{ p: 2.5 }}>
            collapse - only available in paid version
          </Typography>
        );
      case 'item':
        return <NavItem key={menuItem.id} item={menuItem} level={1} />;
      default:
        return (
          <Typography key={menuItem.id} variant="h6" color="error" align="center">
            Fix - Group Collapse or Items
          </Typography>
        );
    }
  });

  return (
    <List
      subheader={
                item.title
                && drawerOpen && (
                <Box sx={{ pl: 3, mb: 1.5 }}>
                  <Typography variant="subtitle2" color="textSecondary">
                    {item.title}
                  </Typography>
                  {/* only available in paid version */}
                </Box>
                )
            }
      sx={{ mb: drawerOpen ? 1.5 : 0, py: 0, zIndex: 0 }}
    >
      {navCollapse}
    </List>
  );
}

NavGroup.propTypes = {
  item: PropTypes.object,
};

export default observer(NavGroup);

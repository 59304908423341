import { types } from 'mobx-state-tree';
import Actions from './Actions';
import Views from './Views';

const StoreProps = {
  id: types.maybeNull(types.string),
  organizationId: types.maybeNull(types.string),
  relevantWorkExperience: types.optional(types.number, 5),
  educationAndCourses: types.optional(types.number, 5),
  collaborationAndCommunication: types.optional(types.number, 5),
  employerEngagementLevel: types.optional(types.number, 5),
  drivenAtWorkBy: types.optional(types.number, 5),
  passionRate: types.optional(types.number, 5),
  socialSkills: types.optional(types.number, 5),
  isLoaded: types.optional(types.boolean, false),
};

const Store = types.model(StoreProps).actions(Actions).views(Views);

export default Store;

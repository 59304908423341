import { types } from 'mobx-state-tree';
import Actions from './Actions';
import Views from './Views';

const CompanySize = types.model({
  companySizeId: types.number,
  value: types.optional(types.string, ''),
});

const RemoteWorkPolicy = types.model({
  remoteWorkPolicyId: types.number,
  value: types.optional(types.string, ''),
});

const StoreProps = {
  id: types.string,
  industry: types.optional(types.string, ''),
  headquarters: types.optional(types.string, ''),
  about: types.optional(types.string, ''),
  specialties: types.optional(types.string, ''),
  oneMonthGrowth: types.maybeNull(types.number),
  twoMonthGrowth: types.maybeNull(types.number),
  sixMonthGrowth: types.maybeNull(types.number),
  medianEmployeeTenure: types.maybeNull(types.number),
  followersCount: types.maybeNull(types.number),
  companySize: types.maybeNull(CompanySize),
  remoteWorkPolicy: types.maybeNull(RemoteWorkPolicy),

};

const Store = types.model(StoreProps).actions(Actions).views(Views);

export default Store;

import { flow } from 'mobx-state-tree';
import { get } from 'lodash';
import { refreshToken } from '../../../api/users.api';

import { getPositionFitMetrics, updatePositionAPI, upsertPositionFitMetrics } from '../../../api/positions.api';

const Actions = (self) => ({
  init: (data) => {
    self.id = data.id;
    self.title = get(data, 'title', self.title);
    self.remoteWorkPolicy = get(data, 'remoteWorkPolicy', self.remoteWorkPolicy);
    self.employmentMode = get(data, 'employmentMode', self.employmentMode);
    self.employmentType = get(data, 'employmentType', self.employmentType);
    self.employmentTypeId = get(data, 'employmentTypeId', self.employmentTypeId);
    self.employmentModeId = get(data, 'employmentModeId', self.employmentModeId);
    self.remoteWorkPolicyId = get(data, 'remoteWorkPolicyId', self.remoteWorkPolicyId);
    self.companyOfficeId = get(data, 'companyOfficeId', self.companyOfficeId);
    self.office = get(data, 'office', self.office);
    self.status = get(data, 'status', self.status);
    self.isLoaded = true;
  },

  update: flow(function* update(dataToUpdate) {
    try {
      const payload = {
        id: self.id,
        ...dataToUpdate,
      };
      const result = yield updatePositionAPI(payload);
      if (result?.isSuccess) {
        self.init(result.result);
      }
      return result;
    } catch (e) {
      console.error(e);
      if (e.statusCode === 401) {
        yield refreshToken();
        yield self.update(dataToUpdate);
      }
      return { isSuccess: false, error: e?.message || e?.error?.message };
    }
  }),

  loadFitMetrics: flow(function* loadFitMetrics() {
    try {
      const result = yield getPositionFitMetrics(self.id);
      if (result?.isSuccess) {
        self.positionFitMetrics = result.result;
      }
      return result;
    } catch (e) {
      console.error(e);
      if (e.statusCode === 401) {
        yield refreshToken();
        yield self.loadFitMetrics();
      }
      return { isSuccess: false, error: e.message || e.error.message };
    }
  }),

  updateFitMetrics: flow(function* updateFitMetrics(values) {
    try {
      const payload = {
        positionId: self.id,
        id: self.positionFitMetrics?.id,
        ...values,
      };

      const result = yield upsertPositionFitMetrics(payload);
      if (result?.isSuccess) {
        self.positionFitMetrics = result.result;
      }
      return result;
    } catch (e) {
      console.error(e);
      if (e.statusCode === 401) {
        yield refreshToken();
        yield self.loadFitMetrics();
      }
      return { isSuccess: false, error: e.message || e.error.message };
    }
  }),
});

export default Actions;

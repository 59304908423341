import { flow, getRoot } from 'mobx-state-tree';
import { createNewPosition, fetchAllPositions, updatePositionAPI } from '../../../api/positions.api';
import { refreshToken } from '../../../api/users.api';

const Actions = (self) => ({

  fetchPositionsList: flow(function* fetchPositionsList() {
    try {
      const response = yield fetchAllPositions();
      if (response?.isSuccess) {
        response.result.forEach(self.addNewPosition);
        self.setIsLoaded(true);
      }
    } catch (e) {
      console.error(e);
      if (e.statusCode === 401) {
        yield refreshToken();
        yield self.fetchCandidatesList();
      }
    }
  }),

  createPosition: flow(function* createPosition(newPosition) {
    try {
      const { user } = getRoot(self);
      const result = yield createNewPosition(newPosition);
      if (result?.isSuccess) {
        result.result.user = user.snapshot;
        self.positionsList.set(result.result.id, result.result);
      }
      return result;
    } catch (e) {
      console.error(e);
      if (e.statusCode === 401) {
        yield refreshToken();
        yield self.createPosition(newPosition);
      }

      return { isSuccess: false, error: e.error.message };
    }
  }),

  updatePosition: flow(function* updatePosition(positionId, dataToUpdate) {
    try {
      const positionToUpdate = self.positionsList.get(positionId);

      const positionUpdateResult = yield positionToUpdate.update(dataToUpdate);
      return positionUpdateResult;
    } catch (e) {
      console.error(e);
      if (e.statusCode === 401) {
        yield refreshToken();
        yield self.updatePosition(positionId, dataToUpdate);
      } else if (e.statusCode === 409) {
        return { isSuccess: false, error: 'The Position already exists in the company' };
      }
      return { isSuccess: false, error: e.message };
    }
  }),

  deletePosition: flow(function* deletePosition(positionId) {
    try {
      const payload = {
        id: positionId,
        deletedAt: new Date().toDateString(),
      };

      const result = yield updatePositionAPI(payload);
      if (result.isSuccess) {
        self.positionsList.delete(positionId);
      }
      return result;
    } catch (e) {
      console.error(e);
      if (e.statusCode === 401) {
        yield refreshToken();
        yield self.deletePosition(positionId);
      }

      return { isSuccess: false, error: e.message };
    }
  }),

  addNewPosition(data) {
    self.positionsList.set(data.id, data);
  },
  setIsLoaded(value) {
    self.isLoaded = value;
  },
});

export default Actions;

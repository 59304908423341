import { types, getSnapshot, applySnapshot } from 'mobx-state-tree';
import User from './User';
import AppState from './AppState';
import Candidates from './Candidates';
import Positions from './Positions';
// import Candidate from './Candidate';
import Organization from './Organization';
import FitMetrics from './FitMetrics';
import Reports from './Reports';

const StoreProps = {
  user: types.optional(User, () => User.create()),
  appState: types.optional(AppState, () => AppState.create()),
  candidates: types.optional(Candidates, () => Candidates.create()),
  organization: types.optional(Organization, () => Organization.create()),
  fitMetrics: types.optional(FitMetrics, () => FitMetrics.create()),
  positions: types.optional(Positions, () => Positions.create()),
  // candidate: types.optional(Candidate, () => Candidate.create()),
  reports: types.optional(Reports, () => Reports.create()),
};

const RootStoreModel = types.model(StoreProps).actions((self) => {
  let initialState = {};
  return {
    afterCreate: () => {
      initialState = getSnapshot(self);
    },
    reset: () => {
      applySnapshot(self, initialState);
    },
  };
});

const RootStore = RootStoreModel.create({});

export default RootStore;

export {
  RootStoreModel,
};

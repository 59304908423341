export const EmploymentModeOptions = [{
  value: -1,
  label: 'Undefined',
},
{
  value: 1,
  label: 'Full-Time',
},
{
  value: 2,
  label: 'Part-Time',
},
{
  value: 3,
  label: 'Hourly',
}];

export const EmploymentTypeOptions = [{
  value: -1,
  label: 'Undefined',
},
{
  value: 1,
  label: 'In-House',
},
{
  value: 2,
  label: 'Outsource',
},
{
  value: 3,
  label: 'Freelance',
}, {
  value: 4,
  label: 'Digital Nomad',
}, {
  value: 5,
  label: 'Internship',
}];

export const RemoteWorkPolicy = {
  notDefined: {
    value: -1,
    label: 'Undefined',
  },
  onSite: {
    value: 1,
    label: 'On-Site',
  },
  hybrid: {
    value: 2,
    label: 'Hybrid',
  },
  remote: {
    value: 3,
    label: 'Remote',
  },
};

export const RemoteWorkPolicyOptions = Object.values(RemoteWorkPolicy);

export const GENERAL_COMPANY_ID = '00000000-0000-0000-0000-000000000000';

export const INVALID_LOGO_URL = 'https://static.licdn.com/sc/h/cs8pjfgyw96g44ln9r7tct85f';

import { types } from 'mobx-state-tree';
import Actions from './Actions';

const isDrawerOpen = localStorage.getItem('drawer-open');

const AppStateProps = {
  openItem: types.optional(types.array(types.string), ['dashboard']),
  openComponent: types.optional(types.string, 'buttons'),
  drawerOpen: types.optional(types.boolean, isDrawerOpen === 'true'),
  componentDrawerOpen: types.optional(types.boolean, true),
};

const AppState = types.model(AppStateProps).actions(Actions);

export default AppState;

import { types } from 'mobx-state-tree';
import Actions from './Actions';
import Views from './Views';
import Candidate from '../Candidate';

const listFilters = types.model({
  positionId: types.maybeNull(types.string),
  searchPhrase: types.maybeNull(types.string),
});

const StoreProps = {
  candidatesList: types.map(Candidate),
  total: types.optional(types.number, 0),
  filters: types.optional(listFilters, {}),
  page: types.optional(types.number, 0),
  rowsPerPage: types.optional(types.number, 25),
  isLoading: types.optional(types.boolean, true),
};

const Store = types.model(StoreProps).actions(Actions).views(Views);

export default Store;

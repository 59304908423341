// assets
import {
  BankOutlined,
} from '@ant-design/icons';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import TuneIcon from '@mui/icons-material/Tune';

const company = {
  id: 'company-group',
  title: 'Company',
  type: 'group',
  children: [
    {
      id: 'company-positions',
      title: 'Positions',
      type: 'item',
      url: '/company-positions',
      icon: WorkOutlineIcon,
      // breadcrumbs: false,
    },
    {
      id: 'new-position',
      title: 'New Position',
      type: 'item',
      url: '/company-positions/new-position',
      // icon: WorkOutlineIcon,
      // breadcrumbs: false,
      hidden: true,
    },
    {
      id: 'company-fit-metrics',
      title: 'Fit Metrics',
      type: 'item',
      url: '/company-fit-metrics',
      icon: TuneIcon,
      // breadcrumbs: false,
    },
    {
      id: 'my-company',
      title: 'My Company',
      type: 'item',
      url: '/my-company',
      icon: BankOutlined,
      // breadcrumbs: false,
    },
  ],
};

export default company;

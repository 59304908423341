// assets
import { FileSearchOutlined, FileAddOutlined } from '@ant-design/icons';

const reports = {
  id: 'reports',
  title: 'Reports',
  type: 'group',
  children: [
    {
      id: 'reports',
      title: 'Reports List',
      type: 'item',
      url: '/reports',
      icon: FileSearchOutlined,
    },
    {
      id: 'report-view',
      title: 'Report Summary',
      withTitle: false,
      type: 'item',
      url: '/reports/report-view',
      icon: FileAddOutlined,
    },
  ],
};

export default reports;

import { flow } from 'mobx-state-tree';
import { updatePersonApi } from '../../../api/person.api';
import { updateCandidateAPI } from '../../../api/candidates.api';
import { refreshToken } from '../../../api/users.api';

const Actions = (self) => ({
  updatePerson: flow(function* updatePerson(dataToUpdate) {
    try {
      const payload = {
        id: self.id,
        firstName: dataToUpdate.firstName,
        lastName: dataToUpdate.lastName,
        profileUrl: dataToUpdate.linkedinUrl,
      };

      const response = yield updatePersonApi(payload);
      if (response?.isSuccess) {
        self.firstName = response.result.firstName;
        self.lastName = response.result.lastName;
        self.profileUrl = response.result.profileUrl;
      }
      return response;
    } catch (e) {
      console.error(e);
      if (e.statusCode === 401) {
        yield refreshToken();
        yield self.updatePerson(dataToUpdate);
      } else if (e.statusCode === 409) {
        return { isSuccess: false, error: 'The Candidate already exists in the company' };
      }
      return { isSuccess: false, error: e.message };
    }
  }),
});

export default Actions;

import { types } from 'mobx-state-tree';
import Actions from './Actions';
import Views from './Views';
import { CANDIDATE_STATUS } from '../../constants/candidate';

const StoreProps = {
  id: types.maybeNull(types.string),
  candidateId: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  organizationId: types.maybeNull(types.string),
  status: types.optional(types.enumeration(Object.values(CANDIDATE_STATUS)), CANDIDATE_STATUS.New),
  createdDate: types.optional(types.string, new Date().toLocaleString()),
  fitScore: types.maybeNull(types.number),
  confidenceLevelScore: types.maybeNull(types.number),
  completionRate: types.maybeNull(types.number),
};

const Store = types.model(StoreProps).actions(Actions).views(Views);

export default Store;

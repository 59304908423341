import { flow, getRoot } from 'mobx-state-tree';
import { get } from 'lodash';
import {
  getAllReportsPagination,
  updateReport,
} from '../../../api/reports.api';
import { refreshToken } from '../../../api/users.api';
import { fitScoreMapping } from '../../../utils/fit-score';
import { sortByDate } from '../../../utils/sort';

const mapReport = (report) => ({
  id: report.id,
  createdDate: new Date(report.createdDate).toDateString(),
  candidateName: get(report, 'candidate.person.name'),
  positionId: get(report, 'candidate.position.id'),
  position: get(report, 'candidate.position.title'),
  user: `${get(report, 'candidate.user.firstName')} ${get(report, 'candidate.user.lastName')}`,
  status: get(report, 'status'),
  fitScore: get(report, 'fitScore'),
  fitResult: fitScoreMapping(get(report, 'fitScore'))?.label,
});

const Actions = (self) => ({

  setPositionId(value) {
    self.filters.positionId = value;
  },
  setSearchPhrase(value) {
    self.filters.searchPhrase = value;
  },

  setPagination(page, rowsPerPage) {
    self.page = page;
    if (rowsPerPage) {
      self.rowsPerPage = rowsPerPage;
    }
  },

  fetchReportsList: flow(function* fetchReportsList() {
    try {
      self.setIsLoading(true);
      const filters = {};
      if (self.filters.positionId) {
        filters.positionId = self.filters.positionId;
      }

      if (self.filters.searchPhrase) {
        filters.candidateName = self.filters.searchPhrase;
      }
      const filtersQuery = new URLSearchParams(filters).toString();

      const offset = (self.page * self.rowsPerPage);

      const response = yield getAllReportsPagination(offset, self.rowsPerPage, filtersQuery);
      if (response?.isSuccess) {
        self.list = {};
        self.total = response.result.count;
        response.result.results.sort(sortByDate).forEach((report) => {
          const reportMapped = mapReport(report);

          self.list.set(report.id, reportMapped);
        });
        self.setIsLoading(false);
        return response;
      }
    } catch (e) {
      console.error(e);
      if (e.statusCode === 401) {
        yield refreshToken();
        yield self.fetchCandidatesList();
      }
    }
    return null;
  }),

  deleteReport: flow(function* deleteReport(reportId) {
    try {
      const payload = {
        id: reportId,
        deletedAt: new Date().toDateString(),
      };

      const result = yield updateReport(payload);
      if (result.isSuccess) {
        self.list.delete(reportId);
      }
      return result;
    } catch (e) {
      console.error(e);
      if (e.statusCode === 401) {
        yield refreshToken();
        yield self.deleteReport(reportId);
      }

      return { isSuccess: false, error: e.message };
    }
  }),

  setIsLoading(value) {
    self.isLoading = value;
  },
});

export default Actions;

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import PageSkeleton from '../components/PageSkeleton';
import { fetchUser } from '../api/users.api';
import { useMST } from '../hooks/useMST';

const ProtectedRoutes = () => {
  const { user, organization } = useMST((store) => ({ user: store.user, organization: store.organization }));
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCurrentUserData = async () => {
      try {
        const response = await fetchUser();
        if (response.isSuccess) {
          user.init(response.result);
          await organization.fetchOrganizationDetails(user.organizationId);
        }

        setLoading(false);
        // navigate('/dashboard');
      } catch (e) {
        if (e.statusCode === 401 && e.message === 'Unauthorized') {
          navigate('/login');
        }
      }
    };

    if (!user.isLoaded) {
      fetchCurrentUserData();
    } else {
      setLoading(false);
    }
  }, []);

  if (isLoading) {
    return <PageSkeleton />;
  }

  if (!user.id) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};

export default observer(ProtectedRoutes);

import { flow } from 'mobx-state-tree';
import { refreshToken } from '../../../api/users.api';
import { updateReport, createReport } from '../../../api/reports.api';

import Report from '../../Report';
import { CANDIDATE_STATUS } from '../../../constants/candidate';

const Actions = (self) => ({
  updatePosition(position) {
    self.position = position;
  },

  createCandidateReport: flow(function* createCandidateReport() {
    try {
      const payload = {
        candidateId: self.id,
        status: CANDIDATE_STATUS.New,
        statusInternal: CANDIDATE_STATUS.New,
      };
      const result = yield createReport(payload);
      if (result?.isSuccess) {
        const newReport = Report.create(result.result);
        self.reports.push(newReport);
      }
      return result;
    } catch (e) {
      console.error(e);
      if (e.statusCode === 401) {
        yield refreshToken();
        yield self.createCandidateReport();
      }
      return { isSuccess: false, error: e.message };
    }
  }),

  updateCandidateReport: flow(function* updateCandidateReport(status) {
    try {
      if (!self.lastReport) {
        yield self.createCandidateReport();
      }

      const payload = {
        id: self.lastReport.id,
        status,
      };
      const result = yield updateReport(payload);
      if (result?.isSuccess) {
        self.lastReport.status = status;
      }
      return result;
    } catch (e) {
      console.error(e);
      if (e.statusCode === 401) {
        yield refreshToken();
        yield self.updateCandidateReport(status);
      }
      return { isSuccess: false, error: e.message };
    }
  }),

  addCv(id) {
    self.candidateCv = { id };
  },
});

export default Actions;

import { types } from 'mobx-state-tree';
import Actions from './Actions';
import Views from './Views';
import CompanyLinkedinInfo from './CompanyLinkedinInfo';
import Office from '../Office';

const StoreProps = {
  id: types.string,
  name: types.optional(types.string, ''),
  domain: types.optional(types.string, ''),
  website: types.optional(types.string, ''),
  yearFounded: types.maybeNull(types.number),
  employeesCount: types.maybeNull(types.number),
  companyLinkedIn: CompanyLinkedinInfo,
  offices: types.optional(types.array(Office), []),
};

const Store = types.model(StoreProps).actions(Actions).views(Views);

export default Store;

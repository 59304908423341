import { types } from 'mobx-state-tree';
import Actions from './Actions';
import Views from './Views';

const StoreProps = {
  id: types.string,
  companyId: types.string,
  address: types.string,
  name: types.string,
  createdDate: types.optional(types.string, new Date().toLocaleString()),
  updatedDate: types.optional(types.string, new Date().toLocaleString()),
};

const Store = types.model(StoreProps).actions(Actions).views(Views);

export default Store;

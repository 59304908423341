import { flow } from 'mobx-state-tree';
import { refreshToken, updateUser } from '../../../api/users.api';

const Actions = (self) => ({

  init: (user) => {
    self.id = user.id;
    self.email = user.email;
    self.firstName = user.firstName;
    self.lastName = user.lastName;
    self.organizationId = user.organizationId;
    self.createdDate = user.createdDate;
    self.isLoaded = true;
  },

  updateUserDetails: flow(function* updateUserDetails(data) {
    try {
      const payload = {
        id: self.id,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
      };
      const response = yield updateUser(payload);
      if (response?.isSuccess) {
        const { result } = response;
        self.firstName = result.firstName || self.firstName;
        self.lastName = result.lastName || self.lastName;
        self.email = result.email || self.email;
      }
      return response;
    } catch (e) {
      console.error(e);
      if (e.statusCode === 401) {
        yield refreshToken();
        yield self.updateUserDetails(data);
      }
      return { isSuccess: false, error: e.message };
    }
  }),
});

export default Actions;

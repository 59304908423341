import { flow } from 'mobx-state-tree';
import { updateCompany, createOffice, updateOffice } from '../../../api/companies.api';

const Actions = (self) => ({
  update: (company) => {
    self.id = company.id;
    self.name = company.name;
    self.domain = company.domain;
    self.website = company.website;
    self.yearFounded = company.yearFounded;
    self.employeesCount = company.employeesCount;
    self.isLoaded = true;
  },

  updateCompanyDetails: flow(function* updateCompanyDetails(data) {
    try {
      const payload = {
        id: self.id,
        name: data.name,
        website: data.website,
        yearFounded: Number(data.yearFounded),
        employeesCount: data.employeesCount,
        companyLinkedIn: {
          id: self.companyLinkedIn.id,
          industry: data.industry,
          remoteWorkPolicyId: data.remoteWorkPolicy,
        },
      };
      const response = yield updateCompany(payload);
      if (response?.isSuccess) {
        self.update(response.result);
        self.companyLinkedIn.update(response.result.companyLinkedIn);
      }

      return response.result;
    } catch (e) {
      console.error(e);
      return null;
    }
  }),

  createCompanyOffice: flow(function* createCompanyOffice(data) {
    try {
      const payload = {
        name: data.name,
        address: data.address,
      };

      const response = yield createOffice(payload);
      if (response?.isSuccess) {
        self.offices.push(response.result);
      }

      return response.result;
    } catch (e) {
      console.error(e);
      return null;
    }
  }),

  updateCompanyOffice: flow(function* updateCompanyOffice(officeId, data) {
    try {
      const payload = {
        id: officeId,
        name: data.name,
      };

      const response = yield updateOffice(payload);
      if (response?.isSuccess) {
        const officeIndex = self.offices.findIndex((item) => item.id === officeId);
        self.offices[officeIndex].name = response.result.name;
      }

      return response.result;
    } catch (e) {
      console.error(e);
      return null;
    }
  }),

  deleteCompanyOffice: flow(function* deleteCompanyOffice(officeId) {
    try {
      const payload = {
        id: officeId,
        deletedAt: new Date().toDateString(),
      };

      const response = yield updateOffice(payload);
      if (response.isSuccess) {
        const officeIndex = self.offices.findIndex((office) => office.id === officeId);
        self.offices.splice(officeIndex, 1);
      }

      return response.result;
    } catch (e) {
      console.error(e);
      return null;
    }
  }),
});

export default Actions;

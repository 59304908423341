// project import

import { Routes, Route, Navigate } from 'react-router-dom';
import { lazy } from 'react';
import { NotificationContainer } from 'react-notifications';
import ThemeCustomization from './themes';
import ScrollTop from './components/ScrollTop';
import MainLayout from './layout/MainLayout';
import MinimalLayout from './layout/MinimalLayout';
import ProtectedRoutes from './routes/ProtectedRoutes';
import Loadable from './components/Loadable';
import ComingSoon from './components/ComingSoon';

// project import

// const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));
const DashboardPage = Loadable(lazy(() => import('./pages/dashboard')));
const CandidatesPage = Loadable(lazy(() => import('./pages/candidates')));
const NewCandidatePage = Loadable(lazy(() => import('./pages/candidates/NewCandidate')));
const CompanyPage = Loadable(lazy(() => import('./pages/company')));
const CompanyFitMetrics = Loadable(lazy(() => import('pages/fit-metrics')));
const OrganizationUsers = Loadable(lazy(() => import('pages/users')));
const AuthLogin = Loadable(lazy(() => import('./pages/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('pages/authentication/Register')));
const ReportsPage = Loadable(lazy(() => import('pages/reports')));
const ViewReportPage = Loadable(lazy(() => import('pages/report-view')));
const UserDetailsPage = Loadable(lazy(() => import('pages/users/UserDetailsPage')));
const PositionsPage = Loadable(lazy(() => import('pages/positions')));
const NewPositionPage = Loadable(lazy(() => import('pages/positions/NewPosition')));

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

function App() {
  return (
    <ThemeCustomization>
      <ScrollTop>
        <Routes>
          {/* Protected routes */}
          <Route path="/" element={<ProtectedRoutes />}>
            <Route path="/" element={<MainLayout />}>
              <Route path="/" element={<Navigate replace to="/dashboard" />} />
              <Route path="/dashboard" element={<DashboardPage />} />
              <Route path="/candidates" element={<CandidatesPage />} />
              <Route path="/candidates/new-candidate" element={<NewCandidatePage />} />
              <Route path="/reports" element={<ReportsPage />} />
              <Route path="/reports/report-view/:reportId" element={<ViewReportPage />} />
              <Route path="/reports/report-view/" element={<ViewReportPage />} />
              <Route path="/company-positions" element={<PositionsPage />} />
              <Route path="/company-positions/new-position" element={<NewPositionPage />} />
              <Route path="/company-fit-metrics" element={<CompanyFitMetrics />} />
              <Route path="/company/system" element={<ComingSoon />} />
              <Route path="/my-company" element={<CompanyPage />} />
              <Route path="/system/users" element={<OrganizationUsers />} />
              <Route path="/system/users-view" element={<UserDetailsPage readonly />} />
              <Route path="/system/users-edit" element={<UserDetailsPage />} />
            </Route>
          </Route>

          {/* Public routes */}
          <Route path="/" element={<MinimalLayout />}>
            <Route path="/login" element={<AuthLogin />} />
            <Route path="/register" element={<AuthRegister />} />
          </Route>

          {/* Default route - should implement 404 page */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <NotificationContainer />
      </ScrollTop>
    </ThemeCustomization>
  );
}

export default App;

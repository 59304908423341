import ajaxUtils from './ajaxUtils';

export const fetchAllPositions = () => ajaxUtils.get('/positions/list');

export const createNewPosition = (position) => ajaxUtils.post('/positions/create', position);

export const updatePositionAPI = (position) => ajaxUtils.patch('/positions/update', position);

export const getPositionFitMetrics = (positionId) => ajaxUtils.get(`/positions/fit-metrics/${positionId}`);

export const upsertPositionFitMetrics = (positionFitMetrics) => ajaxUtils.post('/positions/fit-metrics/upsert', positionFitMetrics);

export const getPositionById = (positionId) => ajaxUtils.get(`/positions/get/${positionId}`);

// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //
// import logoDark from 'assets/images/logo-dark.svg';
import logo from 'assets/images/icons/seal-logo.svg';

function Logo() {
  return (

    <img src={logo} alt="Mantis" width="100" />
  );
}

export default Logo;

import { types } from 'mobx-state-tree';
import Actions from './Actions';
import Views from './Views';
import Company from '../Company';
import User from '../User';
import FitMetrics from '../FitMetrics';

const StoreProps = {
  organizationId: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  company: types.maybeNull(Company),
  isLoaded: types.optional(types.boolean, false),
  users: types.optional(types.array(User), []),
  usersLoaded: types.optional(types.boolean, false),
  fitMetrics: types.maybeNull(FitMetrics),
  keywords: types.optional(types.array(types.string), []),
  keywordsId: types.maybeNull(types.string),
};

const Store = types.model(StoreProps).actions(Actions).views(Views);

export default Store;

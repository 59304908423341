import { RemoteWorkPolicy } from '../../../constants/company';

const Views = (self) => ({
  get addedDate() {
    return new Date(self.createdDate).toDateString();
  },

  get isRemoteWorkPolicy() {
    return RemoteWorkPolicy.remote.value === self.remoteWorkPolicyId;
  },
});

export default Views;
